import * as React from 'react'
import { Container } from '@mui/material'
import { Footer } from '../Footer'
import { AdminHeader } from './AdminHeader'
import { AdminLayoutStyled } from './style'

type Props = Readonly<{
  children: React.ReactNode
}>

export const AdminLayout: React.FC<Props> = ({ children }) => {
  return (
    <AdminLayoutStyled>
      <AdminHeader />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
    </AdminLayoutStyled>
  )
}
