import { Outlet } from 'react-router-dom'
import { AdminLayout } from '@layout/AdminLayout'
import { AdminContextProvider } from './AdminContext'

const Admin = (): React.ReactElement => {
  return (
    <AdminContextProvider>
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </AdminContextProvider>
  )
}

export default Admin
